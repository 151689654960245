'use client'

import { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'next/navigation'
import useSWRInfinite from 'swr/infinite'
import { flatten } from 'lodash-es'
import produce from 'immer'

import Nav from '../nav/index-unixyz'
import { type NavItem } from '../nav/nav-selector/index-unixyz'
import { Robot } from '@/app/components/base/icons/src/public/header-nav/studio'
import { fetchAppList } from '@/service/apps'
import type { AppListResponse } from '@/models/app'
import { useAppContext } from '@/context/app-context'
import { useStore as useAppStore } from '@/app/components/app/store'
import i18n from '@/i18n/i18next-config'

const getKey = (
  pageIndex: number,
  previousPageData: AppListResponse,
  activeTab: string,
  keywords: string,
) => {
  if (!pageIndex || previousPageData.has_more) {
    const params: any = { url: 'apps', params: { page: pageIndex + 1, limit: 30, name: keywords } }

    if (activeTab !== 'all')
      params.params.mode = activeTab
    else
      delete params.params.mode

    return params
  }
  return null
}

const AppNav = () => {
  const { t: systemT } = useTranslation(undefined, { i18n: i18n.systemI18n })

  const { appId } = useParams()
  const { isCurrentWorkspaceEditor } = useAppContext()
  const appDetail = useAppStore(state => state.appDetail)
  const [showNewAppDialog, setShowNewAppDialog] = useState(false)
  const [showNewAppTemplateDialog, setShowNewAppTemplateDialog] = useState(false)
  const [showCreateFromDSLModal, setShowCreateFromDSLModal] = useState(false)
  const [navItems, setNavItems] = useState<NavItem[]>([])

  const { data: appsData, setSize, mutate } = useSWRInfinite(
    appId
      ? (pageIndex: number, previousPageData: AppListResponse) => getKey(pageIndex, previousPageData, 'all', '')
      : () => null,
    fetchAppList,
    { revalidateFirstPage: false },
  )

  const handleLoadmore = useCallback(() => {
    setSize(size => size + 1)
  }, [setSize])

  const openModal = (state: string) => {
    if (state === 'blank')
      setShowNewAppDialog(true)
    if (state === 'template')
      setShowNewAppTemplateDialog(true)
    if (state === 'dsl')
      setShowCreateFromDSLModal(true)
  }

  useEffect(() => {
    if (appsData) {
      const appItems = flatten(appsData?.map(appData => appData.data))
      const navItems = appItems.map((app) => {
        const link = ((isCurrentWorkspaceEditor, app) => {
          return `/app/${app.id}/overview`
        })(isCurrentWorkspaceEditor, app)
        return {
          id: app.id,
          icon_type: app.icon_type,
          icon: app.icon,
          icon_background: app.icon_background,
          icon_url: app.icon_url,
          name: app.name,
          mode: app.mode,
          link,
          unixyz_icon: app?.site?.unixyz_icon,
        }
      })
      setNavItems(navItems)
    }
  }, [appsData, isCurrentWorkspaceEditor, setNavItems])

  // update current app name
  useEffect(() => {
    if (appDetail) {
      const newNavItems = produce(navItems, (draft: NavItem[]) => {
        navItems.forEach((app, index) => {
          if (app.id === appDetail.id)
            draft[index].name = appDetail.name
        })
      })
      setNavItems(newNavItems)
    }
  }, [appDetail, navItems])

  const Logo = () => {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="22" viewBox="0 0 24 22" fill="none">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M6.16025 6.48169C2.44542 8.98609 0 13.2483 0 18.085C0 19.1059 0.108929 20.1011 0.315733 21.0596H5.85658C5.83404 20.9104 5.82235 20.7576 5.82235 20.602C5.82235 18.9339 7.16645 17.5817 8.82449 17.5817C10.4825 17.5817 11.8266 18.9339 11.8266 20.602C11.8266 20.7576 11.8149 20.9104 11.7924 21.0596H17.4295C17.3941 20.6288 17.3761 20.1929 17.3761 19.7529C17.3761 18.3856 17.5503 17.0592 17.8778 15.7951C16.9286 16.1509 15.9012 16.3453 14.8288 16.3453C10.0054 16.3453 6.09527 12.4115 6.09527 7.5589C6.09527 7.19422 6.11735 6.83472 6.16025 6.48169Z" fill="white"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M19.2162 14.7398C16.0898 17.8852 11.0208 17.8852 7.89438 14.7398C4.76795 11.5944 4.76795 6.49474 7.89438 3.34936C10.8795 0.346137 15.8878 0.118252 19.033 2.85002C22.3172 5.74004 24.6186 10.1517 23.796 15.1845C23.583 16.4872 23.1887 17.7099 22.6466 18.8261C21.6332 18.9267 20.6044 18.8314 19.6046 18.5161C20.1771 17.5196 20.5874 16.3917 20.7869 15.1711C20.9431 14.2159 20.9588 13.273 20.8504 12.3673C20.4636 13.2272 19.9188 14.0329 19.2162 14.7398ZM17.9939 9.36817C18.3848 8.975 18.3847 8.33754 17.9939 7.94437C17.6031 7.55119 16.9695 7.55119 16.5787 7.94437C16.1879 8.33754 16.1879 8.975 16.5787 9.36817C16.9695 9.76134 17.6031 9.76134 17.9939 9.36817Z" fill="white"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M13.0121 13.3463C11.537 13.3909 9.96375 12.9007 8.61738 11.8578C6.865 10.5005 5.91422 8.52991 5.90984 6.65321C4.95454 8.78914 5.54015 11.3819 7.46534 12.8731C9.11345 14.1497 11.2863 14.2651 13.0121 13.3463Z" fill="#D7D7D7"/>
      </svg>
    )
  }

  return (
    <>
      <Nav
        isApp
        icon={<Robot className='w-4 h-4' />}
        activeIcon={<Logo />}
        text={systemT('common.menus.apps')}
        activeSegment={['apps', 'app']}
        link='/apps'
        curNav={appDetail}
        navs={navItems}
        createText={systemT('common.menus.newApp')}
        onCreate={openModal}
        onLoadmore={handleLoadmore}
      />
    </>
  )
}

export default AppNav
