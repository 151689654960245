'use client'
import { ChevronDownIcon } from '@heroicons/react/24/solid'
import { useTranslation } from 'react-i18next'
import { useCallback } from 'react'
import { Menu } from '@headlessui/react'
import { debounce } from 'lodash-es'
import { useRouter } from '@/app/components/base/nextjs-router-events'
import { useAppContext } from '@/context/app-context'
import { useStore as useAppStore } from '@/app/components/app/store'
import { DefaultLogo } from '@/app/components/base/icons/src/public/common'

const itemClassName = `
  flex items-center w-full h-10 px-3 text-gray-700 text-[14px]
  rounded-lg font-normal hover:bg-gray-100 cursor-pointer truncate
`

export type NavItem = {
  id: string
  name: string
  link: string
  icon: string
  icon_background: string
  mode: string
  unixyz_icon?: string
}
export type INavSelectorProps = {
  navs: NavItem[]
  curNav?: Omit<NavItem, 'link'>
  createText: string
  isApp: boolean
  onCreate: (state: string) => void
  onLoadmore?: () => void
}

const NavSelector = ({ curNav, navs, createText, isApp, onCreate, onLoadmore }: INavSelectorProps) => {
  const { t } = useTranslation()
  const router = useRouter()
  const { isCurrentWorkspaceManager } = useAppContext()
  const setAppDetail = useAppStore(state => state.setAppDetail)

  const handleScroll = useCallback(debounce((e) => {
    if (typeof onLoadmore === 'function') {
      const { clientHeight, scrollHeight, scrollTop } = e.target

      if (clientHeight + scrollTop > scrollHeight - 50)
        onLoadmore()
    }
  }, 50), [])

  return (
    <div className="">
      <Menu as="div" className="relative inline-block text-left">
        <div>
          <Menu.Button
            className="
              inline-flex items-center w-full h-7 justify-center
              rounded-[10px] pl-2 pr-2.5 text-[14px] font-semibold
              text-white
            "
          >
            <div className='max-w-[180px] truncate' title={curNav?.name}>{curNav?.name}</div>
            <ChevronDownIcon
              className="shrink-0 w-3 h-3 ml-1"
              aria-hidden="true"
            />
          </Menu.Button>
        </div>
        <Menu.Items
          className="
            absolute -left-11 right-0 mt-1.5 w-60 max-w-80
            divide-y divide-gray-100 origin-top-right rounded-lg bg-white
            shadow-lg
          "
        >
          <div className="px-1 py-1 overflow-auto" style={{ maxHeight: '50vh' }} onScroll={handleScroll}>
            {
              navs.filter(nav => !nav.name.includes('#hide')).map(nav => (
                <Menu.Item key={nav.id}>
                  <div className={itemClassName} onClick={() => {
                    if (curNav?.id === nav.id)
                      return
                    setAppDetail()
                    router.push(nav.link)
                  }} title={nav.name}>
                    <div className='mr-2'>
                      { nav?.unixyz_icon ? <img src={`/files/apps/${nav?.id}/app-icon`} className='w-6 h-6 rounded-[6px]' alt="" /> : <DefaultLogo className='rounded-[6px] w-6 h-6'></DefaultLogo>}
                    </div>
                    {nav.name}
                  </div>
                </Menu.Item>
              ))
            }
          </div>
          {/* {isCurrentWorkspaceManager && <Menu.Item>
            <div className='p-1' onClick={onCreate}>
              <div
                className='flex items-center h-12 rounded-lg cursor-pointer hover:bg-gray-100'
              >
                <div
                  className='
                    flex justify-center items-center
                    ml-4 mr-2 w-6 h-6 bg-gray-100 rounded-[6px]
                    border-[0.5px] border-gray-200 border-dashed
                  '
                >
                  <PlusIcon className='w-4 h-4 text-gray-500' />
                </div>
                <div className='font-normal text-[14px] text-gray-700'>{createText}</div>
              </div>
            </div>
          </Menu.Item>} */}
        </Menu.Items>
      </Menu>
    </div>
  )
}

export default NavSelector
