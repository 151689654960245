'use client'

import type { FC } from 'react'
import React, { useEffect, useState } from 'react'
import Script from 'next/script'

enum UmaniType {
  admin = 'admin',
  webapp = 'webapp',
}

type IUmaniProps = {
  umaniType: UmaniType
}

const Umami: FC<IUmaniProps> = ({
  umaniType,
}) => {
  // 如果是 webapp 类型，需要等待 load-views-tracking 事件触发后再加载 umami
  // 否则直接加载
  const [loadUmami, setLoadUmami] = useState(umaniType === UmaniType.admin)
  const [umamiConfig, setUmamiConfig] = useState({
    UMAMI_CONSOLE_SRC: '',
    UMAMI_CONSOLE_ID: '',
    UMAMI_WEB_SRC: '',
    UMAMI_WEB_ID: '',
  })

  const handleEvent = (event: MessageEvent) => {
    if (event.data?.type === 'load-views-tracking')
      setLoadUmami(true)
  }
  useEffect(() => {
    window.addEventListener('message', handleEvent)
    return () => {
      window.removeEventListener('message', handleEvent)
    }
  }, [])

  useEffect(() => {
    const UMAMI_CONSOLE_SRC = document?.body?.getAttribute('data-public-console-vt-src') ?? ''
    const UMAMI_CONSOLE_ID = document?.body?.getAttribute('data-public-console-vt-id') ?? ''
    const UMAMI_WEB_SRC = document?.body?.getAttribute('data-public-web-vt-src') ?? ''
    const UMAMI_WEB_ID = document?.body?.getAttribute('data-public-web-vt-id') ?? ''
    setUmamiConfig({
      UMAMI_CONSOLE_SRC,
      UMAMI_CONSOLE_ID,
      UMAMI_WEB_SRC,
      UMAMI_WEB_ID,
    })
  }, [])

  if (!loadUmami)
    return null

  if (umaniType === UmaniType.admin && umamiConfig.UMAMI_CONSOLE_SRC && umamiConfig.UMAMI_CONSOLE_ID)
    return <Script defer src={umamiConfig.UMAMI_CONSOLE_SRC} data-website-id={umamiConfig.UMAMI_CONSOLE_ID}></Script>
  else if (umaniType === UmaniType.webapp && umamiConfig.UMAMI_WEB_SRC && umamiConfig.UMAMI_WEB_ID)
    return <Script defer src={umamiConfig.UMAMI_WEB_SRC} data-website-id={umamiConfig.UMAMI_WEB_ID}></Script>
  else
    return null
}

export const UmamiAdmin: FC = () => <Umami umaniType={UmaniType.admin} />
export const UmamiWeb: FC = () => <Umami umaniType={UmaniType.webapp} />
