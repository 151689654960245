import(/* webpackMode: "eager" */ "__barrel_optimize__?names=ConfigProvider!=!/app/web/node_modules/.pnpm/antd@5.20.1_react-dom@18.2.0_react@18.2.0__react@18.2.0/node_modules/antd/es/index.js");
import(/* webpackMode: "eager" */ "/app/web/app/components/base/nextjs-router-events/provider.tsx");
import(/* webpackMode: "eager" */ "/app/web/app/components/base/nextjs-router-events/useRouteChangeEvents.ts");
import(/* webpackMode: "eager" */ "/app/web/app/components/base/nextjs-router-events/useRouter.ts");
import(/* webpackMode: "eager" */ "/app/web/app/components/base/umami/index.tsx");
import(/* webpackMode: "eager" */ "/app/web/app/components/header/header-wrapper.tsx");
import(/* webpackMode: "eager" */ "/app/web/app/components/header/index.tsx");
import(/* webpackMode: "eager" */ "/app/web/app/components/swr-initor.tsx");
import(/* webpackMode: "eager" */ "/app/web/context/app-context.tsx");
import(/* webpackMode: "eager" */ "/app/web/context/event-emitter.tsx");
import(/* webpackMode: "eager" */ "/app/web/context/modal-context.tsx");
import(/* webpackMode: "eager" */ "/app/web/context/provider-context.tsx");
import(/* webpackMode: "eager" */ "/app/web/node_modules/.pnpm/next@14.1.1_@babel+core@7.25.2_react-dom@18.2.0_react@18.2.0__react@18.2.0_sass@1.61.0/node_modules/next/dist/client/script.js")